import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { routes } from '../../shrl.routes';
import {
  ENavigationUnitComponentSelection,
  INavigationDataTreeUnit,
  getNavigationTree,
} from '../model/navigation.model';
import { EUserRole } from '../model/user.model';

export interface INavigationUnitsFilterOptions {
  flatten?: boolean;
  componentSelection?: ENavigationUnitComponentSelection;
  paths?: string[];
  visibleForUsers?: EUserRole[];
  containsLabel?: string[];
  hasLabel?: boolean;
  hasChildren?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  readonly #router = inject(Router);

  #navigationUnits$ = new BehaviorSubject<INavigationDataTreeUnit[]>([]);
  navigationTreeUnits$ = this.#navigationUnits$.asObservable();

  #sidenavOpened$ = new BehaviorSubject<boolean>(false);
  sidenavOpened$ = this.#sidenavOpened$.asObservable();

  #isSidenavActive$ = new BehaviorSubject<boolean | null>(null);
  isSidenavActive$ = this.#isSidenavActive$.asObservable();

  constructor() {
    this.#createNavigationUnits();
    this.#trackRouting();
  }

  // SIDE NAV
  /////////////////////////////////////////////////////////////////////////////////////
  setSideNavActive = (active: boolean) => this.#isSidenavActive$.next(active);

  sidenavToggle = () => this.#sidenavOpened$.next(!this.#sidenavOpened$.value);

  sidenavOpened(isOpen: boolean) {
    if (this.#sidenavOpened$.value !== isOpen) {
      this.#sidenavOpened$.next(isOpen);
    }
  }

  // NAVIGATION UNITS
  /////////////////////////////////////////////////////////////////////////////////////

  #createNavigationUnits() {
    const units = getNavigationTree(routes);
    this.#navigationUnits$.next(units);
  }

  get activeNavigationUnit$(): Observable<INavigationDataTreeUnit | null> {
    return this.navigationTreeUnits$.pipe(
      map((units) => this.#getActiveNavigationUnit(units))
    );
  }

  #getActiveNavigationUnit(
    units: INavigationDataTreeUnit[] | undefined
  ): INavigationDataTreeUnit | null {
    if (units == null) {
      return null;
    }

    for (const unit of units) {
      if (unit.matchFullPath) {
        return unit;
      }

      const childUnit = this.#getActiveNavigationUnit(unit.navigationChildren);
      if (childUnit) {
        return childUnit;
      }
    }

    return null;
  }

  // if there will be such need, we can add custom options instead of flatten
  // there could be options like: onlyWithLabels, flatten and so on
  getFilterednavigationUnits$ = (
    filterOptions: INavigationUnitsFilterOptions
  ): Observable<INavigationDataTreeUnit[]> =>
    this.navigationTreeUnits$.pipe(
      map((units) => {
        let filteredUnits = [...units];
        if (filterOptions.flatten) {
          filteredUnits = this.#flatNavigationUnits(filteredUnits);
        }
        return this.#filterNavigationUnitsTreeRecursive(
          filteredUnits,
          filterOptions
        );
      })
    );

  // unify logic to separate methods = LOCK-1071
  #filterNavigationUnitsTreeRecursive(
    units: INavigationDataTreeUnit[],
    filterOptions: INavigationUnitsFilterOptions
  ): INavigationDataTreeUnit[] {
    return units
      .map((unit) => {
        const filteredUnit: INavigationDataTreeUnit = { ...unit };

        // recursive
        if (filteredUnit.navigationChildren?.length) {
          filteredUnit.navigationChildren =
            this.#filterNavigationUnitsTreeRecursive(
              filteredUnit.navigationChildren,
              filterOptions
            );
        }

        // filter by component, add redirect if needed
        if (
          filterOptions.componentSelection != null &&
          !this.#isUnitComponentSelection(
            filteredUnit,
            filterOptions.componentSelection
          )
        ) {
          if (filteredUnit.navigationChildren?.length) {
            filteredUnit.isRedirected = true;
          } else {
            return undefined;
          }
        }

        // filter by paths
        if (
          filterOptions.paths?.length &&
          !this.#isUnitContainsPaths(filteredUnit, filterOptions.paths)
        ) {
          return undefined;
        }

        // filter by users
        if (
          filterOptions.visibleForUsers?.length &&
          !this.#isUnitContainsUsers(
            filteredUnit,
            filterOptions.visibleForUsers
          )
        ) {
          return undefined;
        }

        // filter if has label
        if (filterOptions.hasLabel && !unit?.label) {
          return undefined;
        }

        // filter if contains label
        if (
          filterOptions.containsLabel &&
          !this.#isUnitContainsAnyLabel(
            filteredUnit,
            filterOptions.containsLabel
          )
        ) {
          return undefined;
        }

        // filter by has children
        if (
          filterOptions.hasChildren &&
          (!unit?.navigationChildren || unit.navigationChildren.length === 0)
        ) {
          return undefined;
        }

        return filteredUnit;
      })
      .filter(Boolean) as INavigationDataTreeUnit[];
  }

  #isUnitComponentSelection = (
    unit: INavigationDataTreeUnit,
    component: ENavigationUnitComponentSelection
  ): boolean => !!unit.componentSelection?.includes(component);

  #isUnitContainsPaths = (
    unit: INavigationDataTreeUnit,
    paths: string[]
  ): boolean => {
    return paths.some((path) => unit.fullPath?.includes(path));
  };

  #isUnitContainsUsers = (
    unit: INavigationDataTreeUnit,
    users: EUserRole[]
  ): boolean => {
    return users.some((user) => unit.visibleForUsers?.includes(user));
  };

  #isUnitContainsAnyLabel = (
    unit: INavigationDataTreeUnit,
    labels: string[]
  ): boolean => labels.some((label) => unit.label?.includes(label));

  #flatNavigationUnits(
    units: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] {
    const flattenedUnits: INavigationDataTreeUnit[] = [];

    units.forEach((unit) => {
      if (unit.navigationChildren && unit.navigationChildren.length > 0) {
        flattenedUnits.push(
          ...this.#flatNavigationUnits(unit.navigationChildren)
        );
      }
      const flattenedUnit = { ...unit };
      flattenedUnit.navigationChildren = undefined;
      flattenedUnits.push(flattenedUnit);
    });

    return flattenedUnits;
  }

  // TRACKING NAVIGATION UNITS
  /////////////////////////////////////////////////////////////////////////////////////

  #trackRouting = () =>
    this.#router.events.pipe(takeUntilDestroyed()).subscribe((val) => {
      if (val instanceof NavigationStart) {
        this.sidenavOpened(false);
      } else if (val instanceof NavigationEnd) {
        this.#setActiveNavigationUnit(val.url);
      }
    });

  #setActiveNavigationUnit = (url: string) =>
    this.#navigationUnits$.next(
      this.#getActiveNavigationUnitByUrl(url, this.#navigationUnits$.value)
    );

  #getActiveNavigationUnitByUrl = (
    url: string,
    units: INavigationDataTreeUnit[]
  ): INavigationDataTreeUnit[] =>
    units.map((unit) => {
      unit.matchFullPath = url === unit.fullPath;
      unit.matchPartialPath = url.startsWith(unit.fullPath);

      if (unit.navigationChildren && unit.navigationChildren.length > 0) {
        this.#getActiveNavigationUnitByUrl(url, unit.navigationChildren);
      }

      return unit;
    });
}
