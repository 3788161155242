// import {
//   ENavigationUnitComponentSelection,
//   ENavigationRoute,
// } from '../../core/model/navigation.model';
// import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';

export const SUBSCRIPTIONS_ROUTES: IShrlRoutes = [
  // HIDDEN TEMPORARY
  // {
  //   path: ENavigationRoute.SUBSCRIPTIONS,
  //   loadComponent: () =>
  //     import('./pages/subscriptions.component').then(
  //       (x) => x.SubscriptionsComponent
  //     ),
  //   data: {
  //     componentSelection: [
  //       ENavigationUnitComponentSelection.SIDENAV,
  //       ENavigationUnitComponentSelection.TOPBAR,
  //     ],
  //     iconName: 'shrl_subscriptions',
  //     visibleForUsers: [EUserRole.ROLE_USER],
  //     label: 'SUBSCRIPTIONS.LABEL',
  //   },
  // },
];
