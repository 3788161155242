import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map } from 'rxjs';
import { AccountDataService } from '../../services/auth/account-data.service';
import { EUserRole } from '../../model/user.model';

export const userOrAdminGuard: CanActivateFn = () =>
  inject(AccountDataService).userRoles$.pipe(
    map((roles) =>
      roles.some((role) =>
        [EUserRole.ROLE_USER, EUserRole.ROLE_ADMIN].includes(role)
      )
    )
  );
